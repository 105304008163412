<template>
  <div class="login-container" :style="infoStyle">
    <div>
      <div class="logos"><img src="../../assets/img/logo.png" alt=""><img src="../../assets/img/logo_02.png" alt=""></div>
      <el-form class="login-form" auto-complete="on" label-position="left">
        <div class="title-container">
          <h3 class="title">用户登录</h3>
        </div>
        <el-form-item prop="username">
          <span class="svg-container">
            <img src="../../assets/img/user.png" alt />
          </span>
          <el-input
            v-model="loginForm.username"
            placeholder="用户名"
            name="username"
            type="text"
            tabindex="1"
            auto-complete="on"
            @focus="inputuser"
          />
        </el-form-item>
        <div
          style="color:#C64040;font-size:12px;margin-top:2px;margin-left:8px;height:14px;"
          class="userNaN"
        ></div>
        <el-form-item prop="password">
          <span class="svg-container">
            <img src="../../assets/img/pwd.png" alt />
          </span>
          <el-input
            :key="passwordType"
            v-model="loginForm.password"
            :type="passwordType"
            placeholder="密码"
            name="password"
            tabindex="2"
            auto-complete="on"
            @keyup.enter.native="handleLogin"
            @focus="inputpwd"
          />
          <span class="show-pwd" @click="showPwd">
            <img src="../../assets/img/check.png" alt v-if="passwordType === 'password'" />
            <img src="../../assets/img/hide.png" alt v-else />
            <!-- <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" /> -->
          </span>
        </el-form-item>
        <div
          style="color:#C64040;font-size:12px;margin-top:2px;margin-left:8px;height:14px;"
          class="pwdNaN"
        ></div>
        <el-button
          type="primary"
          style="width:100%;margin-bottom:20px;"
          @click.native.prevent="handleLogin"
        >登录</el-button>
        <div class="otherLogins" @click="otherLogin">微信登录</div>
      </el-form>
      <!-- 二维码弹框开始 -->
      <el-dialog
        title="微信扫码"
        :visible.sync="centerDialogVisible"
        top="15%"
        width="20%"
        center
        @close="close"
      >
        <div id="qrcode" ref="qrcode"></div>
      </el-dialog>
      <!--二维码弹框结束 -->
    </div>
    <div class="footer">
      <p >蜀ICP备16034694号-1</p>
      <p></p>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import { path } from "../../api/api.js";
import axios from "axios";
import qs from "qs";
import Cookies from "js-cookie";
export default {
  data() {
    return {
      loginForm: {
        username: "",
        password: ""
      },
      passwordType: "password",
      redirect: undefined,
      state: true,
      userErrorTip: "账号错误",
      userError: false,
      passErrorTip: "密码错误",
      passError: false,

      centerDialogVisible: false,
      code: "",
      time: "",
      infoStyle: {
        background:"url(" +require("../../assets/img/bgi.jpg") +")",
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat"
      },
      prompts:'',//提示信息内容
    };
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirectC;
      },
      immediate: true
    }
  },
  methods: {
    // 点击密码显示或隐藏
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
    },

    // 登陆
    handleLogin() {
      let userName = this.loginForm.username.trim();
      let passWord = this.$md5(this.loginForm.password.trim()).toUpperCase(); //加密密码
      let loginData = qs.stringify({ userId: userName, password: passWord }); //post请求用qs
      if (this.loginForm.password !== "" && this.loginForm.username !== "") {
        this.$axios
          .post("/user/doLogin", loginData, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
            }
          })
          .then(res => {
            const ret = res.data;
            if (ret.state === 1) {
              Cookies.remove('Token');
              Cookies.remove('userId');
              Cookies.set("userId",userName);
              Cookies.set("Token", ret.token); //登录成功后将token存储在cookie之中
              sessionStorage.setItem("userId", userName);
              sessionStorage.setItem("Token", ret.token);
              // this.getPermissions();
              this.$router.push("/DataShow");
            } else {
              this.prompts = res.data.msg;
              this.DeleteFailure();
            }
          });
      } else {
        //账号密码为空
        if (this.loginForm.username == "") {
          document.querySelector(".userNaN").innerHTML = "账号不能为空 !";
        }
        if (this.loginForm.password == "") {
          document.querySelector(".pwdNaN").innerHTML = "密码不能为空 !";
        }
      }
    },
    // 微信登录
    otherLogin() {
      this.centerDialogVisible = true;
      this.$axios.post("user/getLoginUrl").then(res => {
        const url = res.data.url;
        this.code = res.data.code;
        let qr = new QRCode("qrcode", {
          width: 150,
          height: 150, 
          text: url,
          render: "canvas",
          background: "#f0f",
          foreground: "#ff0"
        });
        this.time = setInterval(() => {
          this.noDatalogin();
        }, 2000);
      });
    },
    // 扫码登录
    noDatalogin() {
      this.$axios
        .post("user/loginWithCode", qs.stringify({ code: this.code }))
        .then(res => {
          if (res.data.state === 1) {
            const ret = res.data;
            Cookies.remove("Token");
            Cookies.remove("userId");
            sessionStorage.setItem("Token",ret.token);
            Cookies.set("Token", ret.token); //登录成功后将token存储在cookie之中
            Cookies.set("userId",ret.userId);
            sessionStorage.setItem("userId", ret.userId);
            this.$router.push("/DataShow");
            clearInterval(this.time);
          }
        });
    },
    // 清空二维码
    close() {
      document.querySelector("#qrcode").innerHTML = "";
      clearInterval(this.time);
    },
    //隐藏消息提示
    inputuser() {
      document.querySelector(".userNaN").innerHTML = "";
    },
    inputpwd() {
      document.querySelector(".pwdNaN").innerHTML = "";
    },
    // 消息提示
    DeleteFailure() {
      this.$message.error(this.prompts);
    },


   
  }
};
</script>

<style lang="scss">
$bg: #333AB5;
$light_gray: #fff;
$cursor: #fff;



/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 88.3%;

    input {
      background: transparent;
      border: 0px;
      padding-left: 6px;

    }
  }

  .el-form-item {
    border: 1px solid #CED1D9;
    background:#E8F0FC;
    border-radius: 5px;
    color: #454545;
    height: 42px;
  }
}
</style>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

.login-container {
  min-height: 100%;
  width: 100%;
  background-color: $bg;
  overflow: hidden;

  .login-form {
    position: relative;
    width: 350px;
    max-width: 100%;
    margin: 0;
    padding: 20px;
    position: absolute;
    right: 15%;
    top: 30%;
    overflow: hidden;
    background-color: #fff;
  }
  .el-form-item {
    margin-bottom: 0;
  }
  .svg-container {
    padding: 0px 5px 6px 8px;
    color: $dark_gray;
    width: 30px;
    display: inline-block;
  }
  .title-container {
    position: relative;

    .title {
      font-size: 20px;
      color: #000;
      margin: 0px auto 30px auto;
      text-align: center;
      font-weight: bold;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 4px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
}
.pwd {
  text-align: right;
  color: white;
}
.pwd > span:hover {
  color: rgb(65, 126, 206);
}
.otherLogins {
  text-align: right;
  color: rgb(65, 126, 206);
  font-size: 14px;
}
.otherLogins:hover {
  cursor: pointer; 
  font-weight: 600;
}
#qrcode {
  width: 150px;
  height: 150px;
  margin: 0 auto 20px;
}
.logos{
  position: absolute;
  top:30%;
  left:15%;
}
.logos>img:first-child{
  width:40px;
  height: 40px;
  vertical-align: middle;
}
.logos>img:nth-child(2){
  width:190px;
  height: 75px;
  vertical-align: middle;
}
.footer{
  width: 100%;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  bottom: 0;
  font-size: 14px;
  color:#fff;
}
</style>
